"use strict"
///include /assets/js/app/p/basket.js
///include /assets/js/app/p/spayment-item.js
///include /assets/js/app/s/basket-item.js
///include /assets/js/plugins/network-property-set.js
///include /assets/js/app/s/catalogue.js

/**
 * @typedef {"any" | "continuousAuthority" | "immediate"} abstractPaymentType
 */
/**
 * @typedef {SPaymentItem["type"] | abstractPaymentType} generalPaymentType
 */
/**
 * The whole basket
 */
class SBasket extends NetworkPropertySet {
    constructor() {
        super()
        this.basketIsOwned = !!sessionStorage.basketIsOwned
        /** @type {?GET.Stack.Basket.$ns.basket.ref.output} */
        this.content = null

        /** @type {?{[ref: string]: SBasketItem}} */
        this.items = null

        this.discountCodes = null;
    }

    /**
     * The general payment type for all items in the basket. 0+ items.
     */
    get allGeneralPaymentTypes() {
        if(!this.items) return null
        /**
         * @type {generalPaymentType[]}
         */
        const out = []
        for(const i of Object.values(this.items)) {
            out.push(
                ...i.allGeneralPaymentTypes
            )
        }
        return [...new Set(out)]
    }

    /** @type {?string} eg "GBP" */
    get currency() {
        if( this.itemsFlat && this.itemsFlat.length > 0 ){
            return this.itemsFlat[0].currency;
        } else {
            return (UserCatalogue.inst).currency;
        }
    }

    get currencySymbol() {
        return (UserCatalogue.inst).currencySymbol;
    }

    get domainStore() {
        if(!this._domainStore) {
            this._domainStore = SDomainStore.inst
            this._domainStore.catalogue.preload(["config", "userVATRate"])
        }
        return this._domainStore
    }

    /**
     * @type {?SBasketItem[]}
     */
    get itemsFlat() {
        if(!this.items) return null
        const out = []
        for(const item of Object.values(this.items)) {
            const items = item.itemsFlat
            if(!items) return null
            out.push(item, ...items)
        }

        // Compress 10GB mailboxes to one single XXGB upgrade.
        // backend it still purchases X 10GB upgrades
        var gbByMailbox = {};
        var nonMailboxUpgrades = out.filter( i => i.productCode != "custom_limit_addon:mailbox_quota_addon" );
        var mailboxUpgrades    = out.filter( i => i.productCode === "custom_limit_addon:mailbox_quota_addon" );
        if( mailboxUpgrades.length > 0 ){
            for(const mb of mailboxUpgrades){
                if( !gbByMailbox[mb.serviceConfiguration.mailboxId] ){
                    gbByMailbox[mb.serviceConfiguration.mailboxId] = 10;
                } else {
                    gbByMailbox[mb.serviceConfiguration.mailboxId] += 10;
                }
            }
        }
        // Compress them
        var groupedItems = [];
        for(const mailId of Object.keys(gbByMailbox)){
            var item = mailboxUpgrades.find( u => u.serviceConfiguration.mailboxId == mailId );
            var newItem = item && item.exportObject();
            if( newItem ){
                newItem.label = newItem.label.replace("10",gbByMailbox[mailId]);
                newItem.quotedVAT = newItem.quotedVAT * (gbByMailbox[mailId] / 10);
                newItem.quotedPriceFull  = newItem.quotedPriceFull * (gbByMailbox[mailId]/10);
                newItem.expectedCharges.quotedPriceFull = +newItem.quotedPriceFull;
                newItem.expectedCharges.quotedVat = +newItem.quotedVAT;
                groupedItems.push( new SBasketItem(newItem,newItem.ref,newItem.dependant) );
            }
        }
        return nonMailboxUpgrades.concat(groupedItems);
    }
    get networkMethodInfo() {
        return {
            path: `/n/basket/own`,
        }
    }
    get networkPropertyHandlers() {
        return {
            content: () => this.basketIsOwned ?
                $.ajax(this.path) :
                $.ajax({method: "post", url: `/n/getOwnBasket`}).then(
                    () => {
                        this.basketIsOwned = true
                        sessionStorage.basketIsOwned = 1
                        return $.ajax(this.path)
                    }
                )
                ,
            discountCodes: () => $.ajax(`/n/discountCodes`),
            items: () => this.preloadSingle("content").then(
                content => {
                    /** @type {{[ref: string]: SBasketItem}} */
                    const items = {}
                    for(let [ref, item] of Object.entries(content)) {
                        items[ref] = new SBasketItem(item, ref)
                    }
                    const items_flat = []
                    for(const item of Object.values(items)) {
                        //@ts-ignore
                        items_flat.push(item, ...item.itemsFlat)
                    }
                    $(document).trigger(
                        "Basket:updated",
                        [
                            undefined,
                            items_flat,
                        ]
                    )
                    return Promise.resolve(items)
                }
            )
        }
    }
    get path() {
        return `/n/basket/own`
    }
    get ref() {
        return null
    }
    /**
     * @returns {?number}
     */
    get total() {
        if(!this.items) return null
        return Object.values(this.items).reduce(
            (carry, item) => Math.round((carry + item.total) * 100) / 100,
            0.00
        )
    }

    get total_excluding_vat() {
        if(!this.itemsFlat) return null
        var total = 0.00;
        this.itemsFlat.forEach( (i) => {
            total += (+i.expectedCharges.quotedPriceFull - +i.expectedCharges.quotedVat);
        } );
        return total.toFixed(2);
    }

    /**
     * @param {BasketItemSpec} item
     */
    async addItem(item) {
        const itemParts = item.add[0].split("/")
        if(!this.domainStore.catalogue.products) {
            throw new Error("This only works with a domain store")
        }
        /**
         * @type {?SBDomainProduct}
         */
        let product = null
        for(const p of this.domainStore.catalogue.products) {
            if(p instanceof SBDomainProduct && p.matchesName(itemParts[1])) {
                // itemParts[1] is the domain name
                product = p
                break
            }
        }

        if(!product) {
            throw new Error("This only works with domain products")
        }

        let basket_item
        if(item.status.match(/^transfer/)) {
            basket_item = await product.basketItem(
                product.pricing.find(p => p.action == 'transfer'),
                itemParts[1],
                item.quantity[itemParts[1]],
                item.price
            )
        } else {
            basket_item = await product.basketItem(
                product.pricing.find(p => !p.action),
                itemParts[1],
                item.quantity[itemParts[1]],
                item.price
            )
        }
        const pic = PaymentItemCollection.inst
        await this.addWithInfo(
            {
                paymentItemCountries: await pic.preloadSingle("countriesFlat"),
            },
            basket_item
        )
    }

    async reloadContent(silent = false) {
        await this.reload(["items"])
    }

    /**
     * @param {string} key
     */
    async remove(key) {
        await $.ajax({
            method: "delete",
            url: `${this.path}/${key}`
        })
        this.reload(["content", "items"])
    }
    /**
     * @param {AnyBasketItemInst} item
     * @return {Promise<boolean>}
     */
    async removeItem(item) {
        if(item instanceof SBasketItem) {
            await $.ajax({
                method: "delete",
                url: `${this.path}/${item.ref}`
            })
            this.reload(["content", "items"])
            return true
        } else {
            return false
        }
    }
    /**
     * @param {string} type
     * @returns {?boolean}
     */
    supportsPaymentType(type) {
        if(!this.items) return null
        return Object.values(this.items).some(
            item => item.supportsPaymentType(type)
        )
    }
    /**
     * Resets all basket state
     */
    reset() {
        this.reload(["content", "items"])
    }
}
SBasket.prototype.add = NPSUtil.j(
    SBasket,
    "add",
    {
        success() {
            return this.reload(["content", "items"])
        },
    }
)

SBasket.prototype.addDiscountCode = NPSUtil.j(
    SBasket,
    "addDiscountCode",
    {
        success() {
            this.reload(["content", "items"]);
        },
    }
)

SBasket.prototype.addWithInfo = NPSUtil.j(
    SBasket,
    "addWithInfo",
    {
        success() {
            return this.reload(["content", "items"])
        },
    }
)

SBasket.prototype.changeCurrency = NPSUtil.j(
    SBasket,
    "changeCurrency",
    {
        success() {
            this.reload(["content", "items"]);
        },
    }
)

if(typeof AnyBasket != "undefined") {
    //@ts-ignore
    AnyBasket.register(SBasket)
}
